require('dotenv').config();
const root = process.env.REACT_APP_ROOT;

const baseURL = `${root}/api`;
const PUBLIC = `${baseURL}/public`;
const PRIVATE = `${baseURL}/private`;

const PUBLIC_USER = `${PUBLIC}/users`;
const PRIVATE_USER = `${PRIVATE}/users`;
const LOGIN = `${PUBLIC_USER}/login`;
const USERS = `${PRIVATE_USER}/`;
const UPDATE_PASSWORD = `${PRIVATE_USER}/updatePassword`;
const ADD_USER = `${PRIVATE_USER}/`;
const REMOVE_USER = `${PRIVATE_USER}/delete`;
const UPDATE_USER = `${PRIVATE_USER}/update`;
const USER_SECTIONS = `${PRIVATE_USER}/sections`;

const INSTRUCTOR = `${PRIVATE}/instructors`;
const GET_INSTRUCTORS = `${PRIVATE}/instructors/getAll`;
const ADD_INSTRUCTOR = `${INSTRUCTOR}/add`;
const REMOVE_INSTRUCTOR = `${INSTRUCTOR}/delete`;
const UPDATE_INSTRUCTOR = `${INSTRUCTOR}/update`;

const CLASS = `${PRIVATE}/classes/`;
const ADD_CLASS = `${CLASS}/add`;
const REMOVE_CLASS = `${CLASS}/delete`;
const UPDATE_CLASS = `${CLASS}/update`;

const COURSES = `${PRIVATE}/courses/`;
const ADD_COURSE = `${COURSES}/add`;
const REMOVE_COURSE = `${COURSES}delete`;
const UPDATE_COURSE = `${COURSES}/update`;

const SECTIONS = `${PRIVATE}/sections`;
const ADD_SECTION = `${SECTIONS}/add`;
const REMOVE_SECTION = `${SECTIONS}/delete`;
const UPDATE_SECTION = `${SECTIONS}/update`;

const TESTS = `${PRIVATE}/tests`;
const ADD_TEST = `${PRIVATE}/tests/addTest`;
const REMOVE_TEST = `${PRIVATE}/tests/deleteTest`;
const UPDATE_TEST = `${PRIVATE}/tests/update`;
const MARKS = `${TESTS}/marks`;
const MARK = `${TESTS}/mark`;
const UPDATE_MARK = `${TESTS}/updateMarks`;
const USER_MARKS = `${TESTS}/user`;

const NOTIFICATIONS = `${PRIVATE}/notifications`;
const GET_NOTIFICATIONS = `${PRIVATE}/notifications/all`;
const UPDATE_NOTIFICATIONS = `${PRIVATE}/notifications/update`;
const ADD_NOTIFICATIONS_TO_STUDENTS = `${NOTIFICATIONS}/addToStudents`;
const ADD_NOTIFICATIONS_TO_SECTION = `${NOTIFICATIONS}/addToSection`;

const STORIES = `${PRIVATE}/stories`;

const STORY_WATCHES = `${STORIES}/watches`;

const CHARTS = `${PRIVATE}/charts`;

const REMOVE_MESSAGES = `${PRIVATE}/reports/delete-many`;

export {
	LOGIN,
	INSTRUCTOR,
	ADD_INSTRUCTOR,
	REMOVE_INSTRUCTOR,
	UPDATE_INSTRUCTOR,
	ADD_CLASS,
	REMOVE_CLASS,
	UPDATE_CLASS,
	USERS,
	ADD_USER,
	CLASS,
	REMOVE_USER,
	UPDATE_USER,
	COURSES,
	ADD_COURSE,
	REMOVE_COURSE,
	UPDATE_COURSE,
	ADD_SECTION,
	REMOVE_SECTION,
	UPDATE_SECTION,
	SECTIONS,
	USER_SECTIONS,
	TESTS,
	ADD_TEST,
	REMOVE_TEST,
	UPDATE_TEST,
	MARKS,
	MARK,
	UPDATE_MARK,
	ADD_NOTIFICATIONS_TO_STUDENTS,
	ADD_NOTIFICATIONS_TO_SECTION,
	UPDATE_PASSWORD,
	USER_MARKS,
	STORIES,
	GET_NOTIFICATIONS,
	UPDATE_NOTIFICATIONS,
	CHARTS,
	GET_INSTRUCTORS,
	STORY_WATCHES,
	REMOVE_MESSAGES,
	root,
};
